import React from "react"

import Layout from "../components/layout"
import Head from "../components/head"
import Card from "../components/card"
import ContactMe from "../components/contactMe"
import aboutPhoto from "../assets/about.jpg"

const AboutPage = () => {
  return (
    <Layout>
      <Head title="About" />
      <div className="flex flex-col-reverse items-center lg:flex-row lg:justify-center lg:items-start">
        <div className="max-w-xs m-2 lg:w-1/3 lg:mx-0 lg:mt-2">
          <Card
            image={aboutPhoto}
            alt="Me playfully picking nose of troll statue at Disney World"
            caption="Me at Epcot - Disney World in 2019"
          />
        </div>
        <div className="lg:w-2/3 lg:pl-6">
          <h1 className="mb-6 text-3xl font-bold">About Me</h1>
          <p className=" mb-6">
            I am a software engineer with experience in{" "}
            <strong>
              JavaScript, React, Redux, Ruby, Ruby on Rails, PostgreSQL, and the
              MERN stack
            </strong>
            . I am passionate about building tools that help people lead
            happier, healthier lives.
          </p>
          <p className=" mb-6">
            I am versed in the structure of electronic medical record
            applications and have working knowledge of{" "}
            <strong>
              Meaningful Use Certification, HL7, ICD-10, CPT, SNOMED, and UMLS
            </strong>
            .
          </p>
          <p className=" mb-6">
            I am originally from the San Francisco Bay Area, lived in Chicago
            for a few years, and am always up for a new adventure. I look
            forward to working with you and your team!
          </p>
          <ContactMe />
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage
