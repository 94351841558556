import React from 'react';

/**
 * Function to create card component with figure caption and rounded corners for images
 * @function card
 * @param {object} props React props
 * @param {string} props.image path to image source
 * @param {string} props.alt alt text for image
 * @param {string} props.caption descriptive figure caption at bottom of card
 * @returns {JSX.Element} Rendered component
 */

const Card = ({image, alt, caption}) => {

  return (
    <figure className="rounded-lg overflow-hidden">
      <img
        className="object-cover object-center"
        src={image}
        alt={alt} />
      <figcaption className="bg-teal-700 p-2 text-teal-100 text-xs text-center">
        {caption}
      </figcaption>
    </figure>
  )
}

export default Card;